import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

axios.defaults.baseURL = process.env.WP_HOME;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wizardData: null,
    configuration: {
      type: 'classic',
      dimensions: [null, null, null],
      loops: null,
      colour: null,
    },
    estimatedPrice: null,
  },
  mutations: {
    setWizardData(state, data) {
      state.wizardData = data;
    },
    setType(state, type) {
      state.configuration.type = type;
    },
    setDimensions(state, dimensions) {
      state.configuration.dimensions = dimensions;
    },
    setLoops(state, loops) {
      state.configuration.loops = loops;
    },
    setColour(state, colour) {
      state.configuration.colour = colour;
    },
    setEstimatedPrice(state, price) {
      state.estimatedPrice = price;
    },
  },
  actions: {
    async fetchWizardData(context) {
      const res = await axios.get(`/wp-json/wizard/content`);

      if (res.status === 200) {
        context.commit('setWizardData', res.data);
      }
    },
  },
  getters: {
    getWizardData: (state) => {
      return state.wizardData;
    },
    getConfiguration: (state) => {
      return state.configuration;
    },
    getEstimatedPrice: (state) => {
      return state.estimatedPrice;
    },
  },
});

<template>
  <div class="wizard wizard__container" v-if="wizardData">
    <form-wizard ref="wizard" title="" subtitle="" shape="square" color="#EEEEED" stepSize="xs">
      <tab-content>
        <step-one @nextStep="nextStep" />
      </tab-content>
      <tab-content>
        <step-two @nextStep="nextStep" @prevStep="prevStep" />
      </tab-content>
      <tab-content>
        <step-three @nextStep="nextStep" @prevStep="prevStep" />
      </tab-content>
      <tab-content>
        <step-four />
      </tab-content>
      <template slot="footer">
        <div class="hidden"></div>
      </template>
    </form-wizard>
    <debug v-if="debug" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import StepOne from './Wizard/StepOne';
import StepTwo from './Wizard/StepTwo';
import StepThree from './Wizard/StepThree';
import StepFour from './Wizard/StepFour.vue';

import Debug from './Wizard/Debug';

export default {
  components: {
    FormWizard,
    TabContent,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    Debug,
  },
  computed: {
    ...mapGetters({
      wizardData: 'getWizardData',
    }),
  },
  data() {
    return {
      type: null,
      debug: false,
    };
  },
  methods: {
    prevStep() {
      this.$refs.wizard.prevTab();
    },
    nextStep() {
      this.$refs.wizard.nextTab();
    },
  },
};
</script>

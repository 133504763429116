function cubicPrice(config) {
  const length = config.dimensions[1];
  const width = config.dimensions[0];
  const height = config.dimensions[2];
  const margin = 1.1;
  const conversion = 900; // cm2 to SQFT
  const manufacturingCosts = 38.55;
  const overheadCosts = 2.74;

  let top = length * width * 1 * margin;
  let front = (length * 2 + width * 2) * (height + 2) * margin;
  let bottom = (length * 2 + width * 2) * 7 * margin;
  let surface = ((top + front + bottom) / conversion).toFixed(2);
  let leather = surface * 1.25 * 1.8;
  let subtotal = (leather + manufacturingCosts) * 1.1 + overheadCosts;
  let foam = (length + 3) * (width + 1) * height;
  let productCost = foam * 0.0007 + subtotal;

  let actualPrice = productCost.toFixed(0) * 3 + 75;
  let roundedPrice = (parseInt(actualPrice / 10, 10) + 1) * 10;

  return roundedPrice;
}

function classicPrice(config) {
  const length = config.dimensions[1];
  const width = config.dimensions[0];
  const height = 3.4;
  const conversion = 900; // cm2 to SQFT
  const manufacturingCosts = 35.71;
  const overheadCosts = 2.74;

  let surface = ((length * width * height) / conversion).toFixed(2);
  let leather = surface * 1.8;
  let subtotal = (leather + manufacturingCosts) * 1.1 + overheadCosts;
  let foam = (length + 3) * (width + 1) * 5;
  let productCost = foam * 0.0007 + subtotal;

  let actualPrice = productCost * 3 + 75;
  let roundedPrice = (parseInt(actualPrice / 10, 10) + 1) * 10;

  return roundedPrice;
}

export { classicPrice, cubicPrice };

<template>
  <div>
    <h2 class="wizard__title" v-text="content.step_3.step_title.replace('%TYPE%', configuration.type)"></h2>
    <div class="wizard__s3-grid">
      <button
        class="wizard__s3-icon"
        :class="loops != 'without_loops' && loops != null ? 'wizard__s3-icon--inactive' : ''"
        @click="setLoops('without_loops')"
      >
        <img
          :src="configuration.type == 'classic' ? content.step_3.no_loops.classic_img_src : content.step_3.no_loops.cubic_img_src"
          alt="No Loops"
          class="wizard__s3-icon-img"
        />
        <span v-html="content.step_3.no_loops.description" />
      </button>
      <button
        class="wizard__s3-icon"
        :class="loops != 'with_loops' && loops != null ? 'wizard__s3-icon--inactive' : ''"
        @click="setLoops('with_loops')"
      >
        <img
          :src="configuration.type == 'classic' ? content.step_3.with_loops.classic_img_src : content.step_3.with_loops.cubic_img_src"
          alt="No Loops"
          class="wizard__s3-icon-img"
        />
        <span v-html="content.step_3.with_loops.description" />
      </button>
    </div>
    <div class="wizard__s3-colours">
      <h3 v-text="content.step_3.colours_title" class="wizard__s3-colours-title"></h3>
      <ul class="wizard__s3-colours-list">
        <li v-for="(item, index) in content.step_3.colours" :key="index">
          <button
            :style="`background-color: ${item.colour}`"
            class="colour-btn wizard__s3-colours-swatch"
            :data-tippy="item.label"
            :class="colour != null && colour == item.label ? 'selected' : ''"
            @click="setColour(item.label)"
          ></button>
        </li>
      </ul>
    </div>
    <div class="wizard__footer">
      <button @click="$emit('prevStep')" class="wizard__button wizard__button--nav">Vorige</button>
      <button
        @click="calculatePrice(configuration)"
        class="wizard__button wizard__button--nav"
        :class="loops == null || colour == null ? 'wizard__button--disabled' : ''"
      >
        Bereken prijs
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { classicPrice, cubicPrice } from '../../helpers/price-calculations';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  computed: {
    ...mapGetters({
      content: 'getWizardData',
      configuration: 'getConfiguration',
    }),
  },
  data() {
    return {
      loops: null,
      colour: null,
      state: 'idle',
      loopSelected: false,
      colourSelected: false,
    };
  },
  mounted() {
    this.initTooltips();
  },
  methods: {
    initTooltips() {
      const colourBtns = document.querySelectorAll('.colour-btn');
      colourBtns.forEach((btn) => {
        tippy(btn, {
          content: btn.getAttribute('data-tippy'),
        });
      });
    },
    setLoops(loops) {
      this.loops = loops;
      this.$store.commit('setLoops', loops);
    },
    setColour(colour) {
      this.colour = colour;
      this.selectedColour = colour;
      this.$store.commit('setColour', colour);
    },
    calculatePrice(configuration) {
      // Add loading state
      if (this.configuration.type == 'classic') {
        this.$store.commit('setEstimatedPrice', classicPrice(configuration));
        this.$emit('nextStep');
      }
      if (this.configuration.type == 'cubic') {
        this.$store.commit('setEstimatedPrice', cubicPrice(configuration));
        this.$emit('nextStep');
      }
    },
  },
};
</script>

<template>
  <div class="w-full bg-gray-700 p-4">
    <h2 class="text-green-500 font-loew-bold uppercase">
      ALTERIO DEVELOPER DEBUG CONSOLE
    </h2>
    <div class="text-green-500">
      <ul>
        <li>
          TYPE: <code>{{ configuration.type }}</code>
        </li>
        <li>
          DIMENSIONS: <code>{{ configuration.dimensions }}</code>
        </li>
        <li>
          LOOPS: <code>{{ configuration.loops }}</code>
        </li>
        <li>
          COLOUR: <code>{{ configuration.colour }}</code>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      configuration: 'getConfiguration',
    }),
  },
};
</script>

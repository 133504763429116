var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.configuration.type
      ? _c("div", [
          _c("h2", {
            staticClass: "wizard__title",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_2.title.replace(
                  "%TYPE%",
                  _vm.configuration.type
                )
              ),
            },
          }),
          _vm._v(" "),
          _vm.configuration.type === "classic"
            ? _c("div", [
                _c("div", { staticClass: "wizard__s2-grid" }, [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: _vm.content.step_2.classic_img_src,
                        alt: "Custom Classic",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "wizard__s2-form" },
                    [
                      _c("label", {
                        staticClass: "wizard__s2-subtitle",
                        domProps: {
                          textContent: _vm._s(
                            _vm.content.step_2.fields.length_label
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "wizard__s2-select",
                        attrs: {
                          clearable: false,
                          options: ["40 cm", "50 cm", "60 cm"],
                        },
                        model: {
                          value: _vm.classicDimensions.height,
                          callback: function ($$v) {
                            _vm.$set(_vm.classicDimensions, "height", $$v)
                          },
                          expression: "classicDimensions.height",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", {
                        staticClass: "wizard__s2-subtitle",
                        domProps: {
                          textContent: _vm._s(
                            _vm.content.step_2.fields.width_label
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.classicDimensions.length,
                            expression: "classicDimensions.length",
                          },
                        ],
                        staticClass: "wizard__input",
                        attrs: {
                          type: "text",
                          placeholder:
                            _vm.content.step_2.fields.width_placeholder.replace(
                              "%RANGE%",
                              "80 - 300"
                            ),
                        },
                        domProps: { value: _vm.classicDimensions.length },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.classicDimensions,
                              "length",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", {
                        staticClass: "wizard__s2-subtitle",
                        domProps: {
                          textContent: _vm._s(
                            _vm.content.step_2.fields.height_label
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v("5 cm")]),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.configuration.type === "cubic"
            ? _c("div", [
                _c("div", { staticClass: "wizard__s2-grid" }, [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: _vm.content.step_2.cubic_img_src,
                        alt: "Custom Cubic",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "wizard__s2-form" }, [
                    _c("label", {
                      staticClass: "wizard__s2-subtitle",
                      domProps: {
                        textContent: _vm._s(
                          _vm.content.step_2.fields.length_label
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cubicDimensions.height,
                          expression: "cubicDimensions.height",
                        },
                      ],
                      staticClass: "wizard__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          _vm.content.step_2.fields.length_placeholder.replace(
                            "%RANGE%",
                            "30 - 90"
                          ),
                      },
                      domProps: { value: _vm.cubicDimensions.height },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.cubicDimensions,
                            "height",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", {
                      staticClass: "wizard__s2-subtitle",
                      domProps: {
                        textContent: _vm._s(
                          _vm.content.step_2.fields.width_label
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cubicDimensions.length,
                          expression: "cubicDimensions.length",
                        },
                      ],
                      staticClass: "wizard__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          _vm.content.step_2.fields.width_placeholder.replace(
                            "%RANGE%",
                            "80 - 300"
                          ),
                      },
                      domProps: { value: _vm.cubicDimensions.length },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.cubicDimensions,
                            "length",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", {
                      staticClass: "wizard__s2-subtitle",
                      domProps: {
                        textContent: _vm._s(
                          _vm.content.step_2.fields.height_label
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cubicDimensions.thickness,
                          expression: "cubicDimensions.thickness",
                        },
                      ],
                      staticClass: "wizard__input",
                      attrs: {
                        type: "text",
                        placeholder:
                          _vm.content.step_2.fields.height_placeholder.replace(
                            "%RANGE%",
                            "3 - 10"
                          ),
                      },
                      domProps: { value: _vm.cubicDimensions.thickness },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.cubicDimensions,
                            "thickness",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMsg && !_vm.valid
            ? _c("p", {
                staticClass: "wizard__notification wizard__notification--error",
                domProps: { innerHTML: _vm._s(_vm.errorMsg) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "wizard__footer" }, [
            _c(
              "button",
              {
                staticClass: "wizard__button wizard__button--nav",
                on: {
                  click: function ($event) {
                    return _vm.$emit("prevStep")
                  },
                },
              },
              [_vm._v("Vorige")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "wizard__button wizard__button--nav",
                on: {
                  click: function ($event) {
                    return _vm.setDimensions(_vm.configuration.type)
                  },
                },
              },
              [_vm._v("Volgende")]
            ),
          ]),
        ])
      : _c("div", [
          _c("p", {
            staticClass: "wizard__notification",
            domProps: {
              textContent: _vm._s(
                "Oeps, er ging iets mis. Probeer het opnieuw of neem contact met ons op."
              ),
            },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="configuration.type">
      <h2 class="wizard__title" v-text="content.step_2.title.replace('%TYPE%', configuration.type)"></h2>
      <div v-if="configuration.type === 'classic'">
        <div class="wizard__s2-grid">
          <div>
            <img :src="content.step_2.classic_img_src" alt="Custom Classic" />
          </div>
          <div class="wizard__s2-form">
            <label class="wizard__s2-subtitle" v-text="content.step_2.fields.length_label"></label>
            <v-select class="wizard__s2-select" :clearable="false" v-model="classicDimensions.height" :options="['40 cm', '50 cm', '60 cm']"></v-select>
            <label class="wizard__s2-subtitle" v-text="content.step_2.fields.width_label"></label>
            <input
              type="text"
              class="wizard__input"
              v-model="classicDimensions.length"
              :placeholder="content.step_2.fields.width_placeholder.replace('%RANGE%', '80 - 300')"
            />
            <label class="wizard__s2-subtitle" v-text="content.step_2.fields.height_label"></label>
            <p>5 cm</p>
          </div>
        </div>
      </div>
      <div v-if="configuration.type === 'cubic'">
        <div class="wizard__s2-grid">
          <div>
            <img :src="content.step_2.cubic_img_src" alt="Custom Cubic" />
          </div>
          <div class="wizard__s2-form">
            <label class="wizard__s2-subtitle" v-text="content.step_2.fields.length_label"></label>
            <input
              type="text"
              class="wizard__input"
              v-model="cubicDimensions.height"
              :placeholder="content.step_2.fields.length_placeholder.replace('%RANGE%', '30 - 90')"
            />
            <label class="wizard__s2-subtitle" v-text="content.step_2.fields.width_label"></label>
            <input
              type="text"
              class="wizard__input"
              v-model="cubicDimensions.length"
              :placeholder="content.step_2.fields.width_placeholder.replace('%RANGE%', '80 - 300')"
            />
            <label class="wizard__s2-subtitle" v-text="content.step_2.fields.height_label"></label>
            <input
              type="text"
              class="wizard__input"
              v-model="cubicDimensions.thickness"
              :placeholder="content.step_2.fields.height_placeholder.replace('%RANGE%', '3 - 10')"
            />
          </div>
        </div>
      </div>
      <p class="wizard__notification wizard__notification--error" v-if="errorMsg && !valid" v-html="errorMsg"></p>
      <div class="wizard__footer">
        <button @click="$emit('prevStep')" class="wizard__button wizard__button--nav">Vorige</button>
        <button @click="setDimensions(configuration.type)" class="wizard__button wizard__button--nav">Volgende</button>
      </div>
    </div>
    <div v-else>
      <p class="wizard__notification" v-text="'Oeps, er ging iets mis. Probeer het opnieuw of neem contact met ons op.'"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    'v-select': vSelect,
  },
  computed: {
    ...mapGetters({
      content: 'getWizardData',
      configuration: 'getConfiguration',
    }),
  },
  data() {
    return {
      classicDimensions: {
        height: '40 cm',
        length: null,
        thickness: '5 cm',
      },
      cubicDimensions: {
        height: null,
        length: null,
        thickness: null,
      },
      valid: false,
      errorMsg: null,
    };
  },
  methods: {
    setDimensions(type) {
      this.valid = false;
      this.errorMsg = null;
      let length;
      if (type === 'classic') {
        length = parseFloat(this.classicDimensions.length);
        if (length > 79) {
          this.$store.commit('setDimensions', [
            parseFloat(this.classicDimensions.height.replace(',', '.')).toFixed(1),
            parseFloat(this.classicDimensions.length.replace(',', '.')).toFixed(1),
            parseFloat(this.classicDimensions.thickness.replace(',', '.')).toFixed(1),
          ]);
          this.valid = true;
        } else {
          this.errorMsg = 'Zorg ervoor dat alle ingevulde gegevens kloppen.';
        }
      }
      if (type === 'cubic') {
        let height = parseFloat(this.cubicDimensions.height);
        length = parseFloat(this.cubicDimensions.length);
        let thickness = parseFloat(this.cubicDimensions.thickness);

        if (height > 29 && height < 91 && length > 79 && thickness > 2 && thickness < 11) {
          this.$store.commit('setDimensions', [
            parseFloat(this.cubicDimensions.height.replace(',', '.')).toFixed(1),
            parseFloat(this.cubicDimensions.length.replace(',', '.')).toFixed(1),
            parseFloat(this.cubicDimensions.thickness.replace(',', '.')).toFixed(1),
          ]);
          this.valid = true;
        } else {
          this.errorMsg = 'Zorg ervoor dat alle ingevulde gegevens kloppen.';
        }
      }
      if (this.valid) {
        this.$emit('nextStep');
      }
    },
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full bg-gray-700 p-4" }, [
    _c("h2", { staticClass: "text-green-500 font-loew-bold uppercase" }, [
      _vm._v("\n    ALTERIO DEVELOPER DEBUG CONSOLE\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text-green-500" }, [
      _c("ul", [
        _c("li", [
          _vm._v("\n        TYPE: "),
          _c("code", [_vm._v(_vm._s(_vm.configuration.type))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n        DIMENSIONS: "),
          _c("code", [_vm._v(_vm._s(_vm.configuration.dimensions))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n        LOOPS: "),
          _c("code", [_vm._v(_vm._s(_vm.configuration.loops))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n        COLOUR: "),
          _c("code", [_vm._v(_vm._s(_vm.configuration.colour))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default class SingleProductTabs {
  constructor() {
    this.productContentTabs = document.querySelectorAll('.product-content-tab');
    this.buttons = document.querySelectorAll('.change-tab');

    this.buttons.forEach(button => {
      button.addEventListener('click', event => {
        let target = event.target.getAttribute('data-target');
        this.showTab(event, target);
      });
    });
  }

  showTab(event, target) {
    this.productContentTabs.forEach(tab => {
      tab.classList.add('hidden');
    });

    this.buttons.forEach(button => {
      button.classList.remove('bg-gray');
    });

    event.target.classList.add('bg-gray');
    document.getElementById(target).classList.remove('hidden');
  }
}

<template>
  <div class="slider__container container">
    <div class="slider-content" v-if="sliderData.title">
      <h1 v-html="sliderData.title" />
      <button v-if="sliderData.button" v-html="sliderData.button.title" @click="changeLocation(sliderData.button.url)" />
    </div>
    <vue-agile
      :nav-buttons="false"
      :autoplay-speed="3500"
      :speed="300"
      fade="fade"
      :pause-on-dots-hover="false"
      :pauseOnHover="false"
      autoplay="autoplay"
      class="slider-container"
    >
      <img
        v-for="(data, index) in sliderData.slides"
        :key="index"
        class="slide"
        :src="windowWidth < 768 && data.imgSmall != null ? data.imgSmall : data.img"
        @click="changeLocation(data.url)"
      />
    </vue-agile>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';

export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  components: {
    'vue-agile': VueAgile,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    sliderData() {
      return JSON.parse(this.data);
    },
  },
  methods: {
    changeLocation(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss">
.slider__container {
  position: relative;
}
.slider-content {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  transform: translateY(-50%);
  text-transform: uppercase;
  line-height: 1.6;
  color: #fff;
  padding: 0 1rem;

  h1 {
    font-size: 2rem;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  button {
    font-size: 1rem;
    text-transform: uppercase;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
  }
}
.slider-container {
  animation-duration: 600ms;
  animation-name: fadeIn;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 1rem;
  @media (min-width: 768px) {
    margin-top: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
}
.agile__dots {
  bottom: 2rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__track {
  // aspect-ratio: 4/3;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  display: block;
  object-fit: cover;
  object-position: center center;
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  cursor: pointer;

  @media (max-width: 792px) {
    aspect-ratio: 1080 / 1350;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", {
      staticClass: "wizard__title",
      domProps: {
        textContent: _vm._s(
          _vm.content.step_3.step_title.replace(
            "%TYPE%",
            _vm.configuration.type
          )
        ),
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "wizard__s3-grid" }, [
      _c(
        "button",
        {
          staticClass: "wizard__s3-icon",
          class:
            _vm.loops != "without_loops" && _vm.loops != null
              ? "wizard__s3-icon--inactive"
              : "",
          on: {
            click: function ($event) {
              return _vm.setLoops("without_loops")
            },
          },
        },
        [
          _c("img", {
            staticClass: "wizard__s3-icon-img",
            attrs: {
              src:
                _vm.configuration.type == "classic"
                  ? _vm.content.step_3.no_loops.classic_img_src
                  : _vm.content.step_3.no_loops.cubic_img_src,
              alt: "No Loops",
            },
          }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.content.step_3.no_loops.description),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "wizard__s3-icon",
          class:
            _vm.loops != "with_loops" && _vm.loops != null
              ? "wizard__s3-icon--inactive"
              : "",
          on: {
            click: function ($event) {
              return _vm.setLoops("with_loops")
            },
          },
        },
        [
          _c("img", {
            staticClass: "wizard__s3-icon-img",
            attrs: {
              src:
                _vm.configuration.type == "classic"
                  ? _vm.content.step_3.with_loops.classic_img_src
                  : _vm.content.step_3.with_loops.cubic_img_src,
              alt: "No Loops",
            },
          }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.content.step_3.with_loops.description),
            },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "wizard__s3-colours" }, [
      _c("h3", {
        staticClass: "wizard__s3-colours-title",
        domProps: { textContent: _vm._s(_vm.content.step_3.colours_title) },
      }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "wizard__s3-colours-list" },
        _vm._l(_vm.content.step_3.colours, function (item, index) {
          return _c("li", { key: index }, [
            _c("button", {
              staticClass: "colour-btn wizard__s3-colours-swatch",
              class:
                _vm.colour != null && _vm.colour == item.label
                  ? "selected"
                  : "",
              style: "background-color: " + item.colour,
              attrs: { "data-tippy": item.label },
              on: {
                click: function ($event) {
                  return _vm.setColour(item.label)
                },
              },
            }),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "wizard__footer" }, [
      _c(
        "button",
        {
          staticClass: "wizard__button wizard__button--nav",
          on: {
            click: function ($event) {
              return _vm.$emit("prevStep")
            },
          },
        },
        [_vm._v("Vorige")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "wizard__button wizard__button--nav",
          class:
            _vm.loops == null || _vm.colour == null
              ? "wizard__button--disabled"
              : "",
          on: {
            click: function ($event) {
              return _vm.calculatePrice(_vm.configuration)
            },
          },
        },
        [_vm._v("\n      Bereken prijs\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.addOnIsVisible
    ? _c("div", { staticClass: "cushion" }, [
        _c("div", { staticClass: "cushion__part" }, [
          _vm.getProductDimensions
            ? _c(
                "div",
                { staticClass: "cushion__grid" },
                _vm._l(_vm.getProductDimensions, function (dimension, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("h2", { staticClass: "cushion__title" }, [
                        _vm._v(_vm._s(dimension.name)),
                      ]),
                      _vm._v(" "),
                      _vm._l(dimension.options, function (option, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "cushion__margin" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selected[dimension.slug],
                                  expression: "selected[dimension.slug]",
                                },
                              ],
                              staticClass: "hidden",
                              attrs: {
                                id: "custom_attr_dimension_" + option.slug,
                                type: "radio",
                              },
                              domProps: {
                                value: option.slug,
                                checked: _vm._q(
                                  _vm.selected[dimension.slug],
                                  option.slug
                                ),
                              },
                              on: {
                                click: _vm.findMatchingVariationId,
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.selected,
                                    dimension.slug,
                                    option.slug
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "cushion__label",
                                attrs: {
                                  for: "custom_attr_dimension_" + option.slug,
                                },
                              },
                              [_vm._v(_vm._s(option.name))]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getProductColors
            ? _c("div", { staticClass: "cushion__colors" }, [
                _c("h2", { staticClass: "cushion__title" }, [
                  _vm._v(_vm._s(_vm.getProductColors.name)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cushion__dots value" },
                  _vm._l(
                    _vm.getProductColors.options,
                    function (option, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "cushion__margin" },
                        [
                          _c("div", { staticClass: "cushion__margin" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedColor,
                                  expression: "selectedColor",
                                },
                              ],
                              staticClass: "variation-input-color hidden",
                              attrs: {
                                type: "radio",
                                id: "custom_attr_color_" + option.slug,
                              },
                              domProps: {
                                value: option.slug,
                                checked: _vm._q(_vm.selectedColor, option.slug),
                              },
                              on: {
                                click: _vm.findMatchingVariationId,
                                change: function ($event) {
                                  _vm.selectedColor = option.slug
                                },
                              },
                            }),
                            _c("label", {
                              staticClass: "cushion__dot",
                              style: "background-color:" + option.hex,
                              attrs: {
                                for: "custom_attr_color_" + option.slug,
                                "data-tippy": option.name,
                              },
                            }),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.formProcessing
          ? _c("div", { staticClass: "cushion__check" }, [
              _c(
                "svg",
                {
                  staticClass: "animate-spin cushion__spinner",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15",
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import AddToCartVariation from './includes/add-to-cart-variation';
import SingleProductGallery from './includes/single-product-gallery';
import SingleProductTabs from './includes/single-product-tabs';
import Cart from './includes/cart';
import Wallmounts from './includes/wallmounts';
import Cushions from './includes/cushions';
import Tooltips from './includes/tooltips';
import ProductBags from './includes/product-bags';
export default class WooCommerce {
  constructor() {
    this.productWallmount = document.getElementById('productAddOnWallmount');
    this.productCushions = document.getElementById('productAddOnCushion');

    // TO DO: Find element to trigger this class
    new AddToCartVariation();
    new Tooltips('colours');
    new Tooltips('preview');
    new ProductBags();

    if (document.body.classList.contains('winkelmand') || document.body.classList.contains('shoppingcart')) {
      new Cart();
    }

    if (this.productWallmount) {
      new Wallmounts(this.productWallmount);
    }

    if (this.productCushions) {
      new Cushions(this.productCushions);
    }

    if (document.body.classList.contains('single-product')) {
      new SingleProductGallery();
      new SingleProductTabs();
    }
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", {
      staticClass: "wizard__s4-title",
      domProps: { textContent: _vm._s(_vm.content.step_4.title) },
    }),
    _vm._v(" "),
    _c("p", {
      staticClass: "wizard__text",
      domProps: { textContent: _vm._s(_vm.content.step_4.description) },
    }),
    _vm._v(" "),
    _c("h3", { staticClass: "wizard__s4-price" }, [
      _vm._v("€ " + _vm._s(_vm.estimatedPrice) + " incl. BTW"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "wizard__s4-form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm()
          },
        },
      },
      [
        _c("div", { staticClass: "wizard__s4-form-row" }, [
          _c("label", {
            attrs: { for: "name" },
            domProps: { textContent: _vm._s(_vm.content.step_4.name) },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            staticClass: "wizard__input",
            attrs: { type: "text", required: "" },
            domProps: { value: _vm.form.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "name", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s4-form-row" }, [
          _c("label", {
            attrs: { for: "email" },
            domProps: { textContent: _vm._s(_vm.content.step_4.email) },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email",
              },
            ],
            staticClass: "wizard__input",
            attrs: { type: "email", required: "" },
            domProps: { value: _vm.form.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "email", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s4-form-row" }, [
          _c("label", {
            attrs: { for: "phone" },
            domProps: { textContent: _vm._s(_vm.content.step_4.phone) },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.phone,
                expression: "form.phone",
              },
            ],
            staticClass: "wizard__input",
            attrs: { type: "phone", required: "" },
            domProps: { value: _vm.form.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "phone", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s4-form-row" }, [
          _c("label", {
            attrs: { for: "street" },
            domProps: { textContent: _vm._s(_vm.content.step_4.street) },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.street,
                expression: "form.street",
              },
            ],
            staticClass: "wizard__input",
            attrs: { type: "text", required: "" },
            domProps: { value: _vm.form.street },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "street", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s4-form-row" }, [
          _c("label", {
            attrs: { for: "zipcode" },
            domProps: { textContent: _vm._s(_vm.content.step_4.zipcode) },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.zipcode,
                expression: "form.zipcode",
              },
            ],
            staticClass: "wizard__input",
            attrs: { type: "text", required: "" },
            domProps: { value: _vm.form.zipcode },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "zipcode", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s4-form-row" }, [
          _c("label", {
            attrs: { for: "city" },
            domProps: { textContent: _vm._s(_vm.content.step_4.town) },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.city,
                expression: "form.city",
              },
            ],
            staticClass: "wizard__input",
            attrs: { type: "text", required: "" },
            domProps: { value: _vm.form.city },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "city", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s4-form-row" }, [
          _c("label", {
            attrs: { for: "country" },
            domProps: { textContent: _vm._s(_vm.content.step_4.country) },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.country,
                expression: "form.country",
              },
            ],
            staticClass: "wizard__input",
            attrs: { type: "text", required: "" },
            domProps: { value: _vm.form.country },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "country", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s4-form-row--last" }, [
          _c("label", { attrs: { for: "subscribe" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.subscribe,
                  expression: "form.subscribe",
                },
              ],
              attrs: { type: "checkbox", id: "subscribe" },
              domProps: {
                checked: Array.isArray(_vm.form.subscribe)
                  ? _vm._i(_vm.form.subscribe, null) > -1
                  : _vm.form.subscribe,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.form.subscribe,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.form, "subscribe", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "subscribe",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "subscribe", $$c)
                  }
                },
              },
            }),
            _vm._v(" " + _vm._s(_vm.content.step_4.newsletter)),
          ]),
        ]),
        _vm._v(" "),
        _vm.formStatus != "submitted"
          ? _c("input", {
              staticClass: "wizard__button",
              attrs: {
                disabled: _vm.formStatus == "processing",
                type: "submit",
              },
              domProps: { value: _vm.content.step_4.button },
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.formStatus == "submitted"
      ? _c("p", {
          staticClass: "wizard__notification",
          domProps: { innerHTML: _vm._s(_vm.content.step_4.message) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
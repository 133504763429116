var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible && _vm.formState == "idle"
    ? _c("div", { staticClass: "wallmount" }, [
        _c(
          "div",
          { staticClass: "wallmount__grid" },
          [
            _c("h2", {
              staticClass: "wallmount__title",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
            _vm._v(" "),
            _c("h3", { staticClass: "wallmount__price" }, [
              _vm._v("€ "),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.displayPrice) } }),
            ]),
            _vm._v(" "),
            _vm._l(_vm.getSimpleWallmounts, function (wallmount, index) {
              return _c(
                "button",
                {
                  key: index,
                  ref: "simpleWallmountButtons",
                  refInFor: true,
                  staticClass: "wallmount__item",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.setWallmount($event, wallmount, "simple")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "wallmount__image",
                    attrs: { src: wallmount.icon, alt: wallmount.name },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "wallmount__name",
                    domProps: { textContent: _vm._s(wallmount.name) },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "wallmount__set",
                    domProps: {
                      textContent: _vm._s("SET of " + _vm.wallmountQuantity),
                    },
                  }),
                ]
              )
            }),
            _vm._v(" "),
            _vm.getComplexWallmount
              ? _c(
                  "button",
                  {
                    ref: "complexWallmountButton",
                    staticClass: "wallmount__item wallmount__item-large",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setWallmount(
                          $event,
                          _vm.getComplexWallmount,
                          "complex"
                        )
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "wallmount__image-large",
                      attrs: {
                        src: _vm.getComplexWallmount.icon,
                        alt: _vm.getComplexWallmount.name,
                      },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "wallmount__name wallmount-ossi",
                      domProps: {
                        innerHTML: _vm._s(
                          "1 &times; " +
                            _vm.getComplexWallmount.name +
                            " " +
                            parseInt(_vm.selectedLengthValue)
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import Wallmount from './../../components/Product/Wallmount';

export default class Wallmounts {
  constructor(element) {
    const title = element.getAttribute('data-title');
    const simpleWallmounts = element.getAttribute('data-simple-wallmounts');
    const complexWallmount = element.getAttribute('data-complex-wallmount');
    const locale = element.getAttribute('data-locale');

    new Vue({
      el: '#productAddOnWallmount',
      render: h =>
        h(Wallmount, {
          props: {
            title: title,
            simpleWallmounts: simpleWallmounts,
            complexWallmount: complexWallmount,
            locale: locale,
          },
        }),
    });
  }
}

<template>
  <div>
    <h2 class="wizard__s4-title" v-text="content.step_4.title"></h2>
    <p class="wizard__text" v-text="content.step_4.description"></p>
    <h3 class="wizard__s4-price">&euro; {{ estimatedPrice }} incl. BTW</h3>
    <form class="wizard__s4-form" @submit.prevent="submitForm()">
      <div class="wizard__s4-form-row">
        <label for="name" v-text="content.step_4.name"></label>
        <input type="text" v-model="form.name" class="wizard__input" required />
      </div>
      <div class="wizard__s4-form-row">
        <label for="email" v-text="content.step_4.email"></label>
        <input type="email" v-model="form.email" class="wizard__input" required />
      </div>
      <div class="wizard__s4-form-row">
        <label for="phone" v-text="content.step_4.phone"></label>
        <input type="phone" v-model="form.phone" class="wizard__input" required />
      </div>
      <div class="wizard__s4-form-row">
        <label for="street" v-text="content.step_4.street"></label>
        <input type="text" v-model="form.street" class="wizard__input" required />
      </div>
      <div class="wizard__s4-form-row">
        <label for="zipcode" v-text="content.step_4.zipcode"></label>
        <input type="text" v-model="form.zipcode" class="wizard__input" required />
      </div>
      <div class="wizard__s4-form-row">
        <label for="city" v-text="content.step_4.town"></label>
        <input type="text" v-model="form.city" class="wizard__input" required />
      </div>
      <div class="wizard__s4-form-row">
        <label for="country" v-text="content.step_4.country"></label>
        <input type="text" v-model="form.country" class="wizard__input" required />
      </div>
      <div class="wizard__s4-form-row--last">
        <label for="subscribe"><input type="checkbox" v-model="form.subscribe" id="subscribe" /> {{ content.step_4.newsletter }}</label>
      </div>
      <input
        v-if="formStatus != 'submitted'"
        :disabled="formStatus == 'processing'"
        class="wizard__button"
        type="submit"
        :value="content.step_4.button"
      />
    </form>
    <p class="wizard__notification" v-if="formStatus == 'submitted'" v-html="content.step_4.message"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        street: '',
        zipcode: '',
        city: '',
        country: '',
        subscribe: false,
      },
      formStatus: 'idle',
    };
  },
  computed: {
    ...mapGetters({
      content: 'getWizardData',
      configuration: 'getConfiguration',
      estimatedPrice: 'getEstimatedPrice',
    }),
  },
  methods: {
    async submitForm() {
      if (this.formStatus == 'idle') {
        this.formStatus = 'processing';
        const res = await axios.post(`/wp-json/wizard/send_request`, {
          form: this.form,
          configuration: this.configuration,
          price: this.estimatedPrice,
        });

        if (res.status === 200) {
          this.formStatus = 'submitted';
        }
      }
    },
  },
};
</script>

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class Tooltips {
  constructor(type) {
    if ('colours' == type) {
      this.colourTooltip();
    }

    if ('preview' == type) {
      this.previewTooltip();
    }

    const englishSelector = document.querySelector('span.link-to-english');
    if (englishSelector) {
      tippy(englishSelector, {
        content: 'Coming soon!',
      });
    }
  }

  colourTooltip() {
    const labels = document.querySelectorAll('.variation-input-color');

    if (labels.length > 0) {
      labels.forEach((label) => {
        tippy(label.nextSibling, {
          content: label.nextSibling.getAttribute('data-tippy'),
        });
      });
    }
  }

  previewTooltip() {
    const labels = document.querySelectorAll('.bags-preview-colour');

    if (labels.length > 0) {
      labels.forEach((label) => {
        tippy(label, {
          content: label.getAttribute('data-tippy'),
        });
      });
    }
  }
}

import Vue from 'vue';
import Cushion from './../../components/Product/Cushion';

export default class Cushions {
  constructor(element) {
    const title = element.getAttribute('data-title');
    const attrDimensions = element.getAttribute('data-attributes-dimensions');
    const attrColors = element.getAttribute('data-attributes-colors');
    const variations = element.getAttribute('data-variations');
    const locale = element.getAttribute('data-locale');

    new Vue({
      el: '#productAddOnCushion',
      render: h =>
        h(Cushion, {
          props: {
            variations: variations,
            attrDimensions: attrDimensions,
            attrColors: attrColors,
            title: title,
            locale: locale,
          },
        }),
    });
  }
}

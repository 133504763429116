export default class HomeMenu {
  constructor() {
    this.homeMenu = document.getElementById('homeMenu');
    this.homeMenuToggle = document.querySelectorAll('.homeMenuToggle');

    if (this.homeMenuToggle.length > 0) {
      this._addEventListeners();
    }
  }

  _addEventListeners() {
    this.homeMenuToggle.forEach(toggle => {
      toggle.addEventListener('click', () => {
        this._toggleMenu();
      });
    });
  }

  _toggleMenu() {
    this.homeMenu.classList.toggle('is-open');
    document.body.classList.toggle('overflow-hidden');
  }
}

<template>
  <div class="cushion" v-if="addOnIsVisible">
    <div class="cushion__part">
      <div class="cushion__grid" v-if="getProductDimensions">
        <div v-for="(dimension, index) in getProductDimensions" :key="index">
          <h2 class="cushion__title">{{ dimension.name }}</h2>
          <div class="cushion__margin" v-for="(option, i) in dimension.options" :key="i">
            <input
              class="hidden"
              :value="option.slug"
              :id="`custom_attr_dimension_${option.slug}`"
              type="radio"
              v-model="selected[dimension.slug]"
              @click="findMatchingVariationId"
            />
            <label class="cushion__label" :for="`custom_attr_dimension_${option.slug}`">{{ option.name }}</label>
          </div>
        </div>
      </div>
      <div class="cushion__colors" v-if="getProductColors">
        <h2 class="cushion__title">{{ getProductColors.name }}</h2>
        <div class="cushion__dots value">
          <div class="cushion__margin" v-for="(option, index) in getProductColors.options" :key="index">
            <div class="cushion__margin">
              <input
                class="variation-input-color hidden"
                :value="option.slug"
                type="radio"
                v-model="selectedColor"
                :id="`custom_attr_color_${option.slug}`"
                @click="findMatchingVariationId"
              /><label
                :for="`custom_attr_color_${option.slug}`"
                class="cushion__dot"
                :style="`background-color:${option.hex}`"
                :data-tippy="option.name"
              ></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cushion__check" v-if="formProcessing">
      <svg xmlns="http://www.w3.org/2000/svg" class="animate-spin cushion__spinner" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  props: ['variations', 'attrDimensions', 'attrColors', 'title', 'locale'],
  data() {
    return {
      addOnIsVisible: false,
      selected: {
        hoogte: '40cm',
        lengte: '140cm',
        dikte: '5cm',
        afmetingen: 'lumi-40-x-40-cm',
      },
      productForm: null,
      selectedColor: 'inky-blue',
      cushionPrice: null,
      cushionVariationId: null,
      formProcessing: false,
    };
  },
  methods: {
    init() {
      this.addOnIsVisible = true;
      this.findMatchingVariationId();
      setTimeout(() => {
        const labels = document.querySelectorAll('.variation-input-color');

        if (labels.length > 0) {
          labels.forEach((label) => {
            tippy(label.nextSibling, {
              content: label.nextSibling.getAttribute('data-tippy'),
            });
          });
        }
      }, 500);
    },
    findMatchingVariationId() {
      this.formProcessing = true;

      setTimeout(() => {
        let selectedAttributes = {};

        if (this.getProductVariations.length < 25) {
          if (this.locale == 'en_US') {
            selectedAttributes = {
              attribute_pa_afmetingen: this.selected.afmetingen,
              attribute_pa_dikte: '5cm',
              attribute_pa_kleur: this.selectedColor,
            };
          }
          if (this.locale == 'nl_NL') {
            selectedAttributes = {
              attribute_pa_afmetingen: this.selected.afmetingen,
              attribute_pa_dikte: '5cm',
              attribute_pa_kleur: this.selectedColor,
            };
          }
        }

        if (this.getProductVariations.length > 25) {
          if (this.locale == 'en_US') {
            selectedAttributes = {
              attribute_pa_hoogte: this.selected.hoogte,
              attribute_pa_lengte: this.selected.lengte,
              attribute_pa_dikte: this.selected.dikte,
              attribute_pa_kleur: this.selectedColor,
              attribute_pa_loops: 'zonder-lussen',
            };
          }
          if (this.locale == 'nl_NL') {
            selectedAttributes = {
              attribute_pa_hoogte: this.selected.hoogte,
              attribute_pa_lengte: this.selected.lengte,
              attribute_pa_dikte: this.selected.dikte,
              attribute_pa_kleur: this.selectedColor,
              attribute_pa_loops: 'zonder-lussen',
            };
          }
        }

        if (this.getProductVariations.length > 0 && Object.keys(selectedAttributes).length != 0) {
          this.getProductVariations.forEach((variation) => {
            if (
              JSON.stringify(selectedAttributes.attribute_pa_hoogte) === JSON.stringify(variation.attributes.attribute_pa_hoogte) &&
              JSON.stringify(selectedAttributes.attribute_pa_lengte) === JSON.stringify(variation.attributes.attribute_pa_lengte) &&
              JSON.stringify(selectedAttributes.attribute_pa_dikte) === JSON.stringify(variation.attributes.attribute_pa_dikte) &&
              JSON.stringify(selectedAttributes.attribute_pa_loops) === JSON.stringify(variation.attributes.attribute_pa_loops) &&
              JSON.stringify(selectedAttributes.attribute_pa_kleur) === JSON.stringify(variation.attributes.attribute_pa_kleur)
            ) {
              this.cushionVariationId = variation.variation_id;
              this.cushionPrice = variation.display_regular_price;
            }
          });
        }
        this.setNewTotalDisplayPrice();
        this.formProcessing = false;
      }, 600);
    },

    setNewTotalDisplayPrice() {
      setTimeout(() => {
        const totalPrice = document.querySelector('.current-display-price');
        const productPrice = totalPrice.getAttribute('data-product-price');
        totalPrice.innerText = parseInt(this.cushionPrice) + parseInt(productPrice);
      }, 100);
    },

    async submitForm(e) {
      e.preventDefault();
      if (this.cushionVariationId != null) {
        const res = await axios.post(`https://${window.location.hostname}/wp-json/product/add_product`, {
          id: this.cushionVariationId,
          qty: 1,
        });
        if (res.status === 200) {
          this.productForm.submit();
        }
      } else {
        this.productForm.submit();
      }
    },
  },
  computed: {
    getProductVariations() {
      return JSON.parse(this.variations);
    },
    getProductDimensions() {
      return JSON.parse(this.attrDimensions);
    },
    getProductColors() {
      return JSON.parse(this.attrColors);
    },
  },
  mounted() {
    const showBtn = document.querySelector('input[value=with-cushion]');
    const hideBtn = document.querySelector('input[value=without-cushion]');
    const variationButtons = document.querySelectorAll('input[name=attribute_pa_meubel-afmetingen]');
    const formSubmitButton = document.querySelector('button[type=submit]');
    this.productForm = document.querySelector('form.variations_form');

    this.init();

    showBtn.addEventListener('click', () => {
      this.findMatchingVariationId();
      this.addOnIsVisible = true;
    });
    hideBtn.addEventListener('click', () => {
      this.addOnIsVisible = false;
    });

    variationButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        this.selected.lengte = e.target.value.substr(0, 3) + 'cm';
        this.addOnIsVisible = false;
        hideBtn.click();
      });
    });

    formSubmitButton.addEventListener('click', (e) => {
      this.submitForm(e);
    });
  },
};
</script>

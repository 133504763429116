export default class ProductBags {
  constructor() {
    this.BagVariants = document.querySelectorAll('.bag');
    if (this.BagVariants.length > 0) {
      this.init();
    }
  }

  init() {
    const colorDots = document.querySelectorAll('.bag__dot');
    colorDots.forEach((element) => {
      element.addEventListener('click', (event) => {
        let color = event.target.getAttribute('data-color');
        let imgEl = event.target.parentNode.parentNode.parentNode.parentNode.querySelector('.bag__image');
        let variations = JSON.parse(imgEl.getAttribute('data-variants'));
        imgEl.src = variations[color];
      });
    });
  }
}

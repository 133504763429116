<template>
  <div>
    <h2 class="wizard__title" v-text="content.step_1.title"></h2>
    <div class="wizard__s1-grid">
      <div>
        <img @click="setType('classic')" :src="content.step_1.type_1.img_src" :alt="content.step_1.title" />
      </div>
      <div class="wizard__s1-grid-row">
        <h3 class="wizard__subtitle" v-text="content.step_1.type_1.button"></h3>
        <div class="wizard__s1-table">
          <div class="wizard__s1-table-key" v-text="content.step_1.type_1.dimensions.length_label"></div>
          <div class="wizard__s1-table-value" v-text="content.step_1.type_1.dimensions.length_value"></div>
          <div class="wizard__s1-table-key" v-text="content.step_1.type_1.dimensions.width_label"></div>
          <div class="wizard__s1-table-value" v-text="content.step_1.type_1.dimensions.width_value"></div>
          <div class="wizard__s1-table-key" v-text="content.step_1.type_1.dimensions.height_label"></div>
          <div class="wizard__s1-table-value" v-text="content.step_1.type_1.dimensions.height_value"></div>
        </div>
        <button @click="setType('classic')" class="wizard__button" v-text="content.step_1.type_1.button"></button>
      </div>
    </div>
    <div class="wizard__s1-grid">
      <div>
        <img @click="setType('cubic')" :src="content.step_1.type_2.img_src" :alt="content.step_1.title" />
      </div>
      <div class="wizard__s1-grid-row">
        <h3 class="wizard__subtitle" v-text="content.step_1.type_2.button"></h3>
        <div class="wizard__s1-table">
          <div class="wizard__s1-table-key" v-text="content.step_1.type_2.dimensions.length_label"></div>
          <div class="wizard__s1-table-value" v-text="content.step_1.type_2.dimensions.length_value"></div>
          <div class="wizard__s1-table-key" v-text="content.step_1.type_2.dimensions.width_label"></div>
          <div class="wizard__s1-table-value" v-text="content.step_1.type_2.dimensions.width_value"></div>
          <div class="wizard__s1-table-key" v-text="content.step_1.type_2.dimensions.height_label"></div>
          <div class="wizard__s1-table-value" v-text="content.step_1.type_2.dimensions.height_value"></div>
        </div>
        <button @click="setType('cubic')" class="wizard__button" v-text="content.step_1.type_2.button"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      content: 'getWizardData',
    }),
  },
  data() {
    return {
      type: null,
    };
  },
  methods: {
    setType(type) {
      this.$store.commit('setType', type);
      this.$emit('nextStep');
    },
  },
};
</script>

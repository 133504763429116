var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider__container container" },
    [
      _vm.sliderData.title
        ? _c("div", { staticClass: "slider-content" }, [
            _c("h1", { domProps: { innerHTML: _vm._s(_vm.sliderData.title) } }),
            _vm._v(" "),
            _vm.sliderData.button
              ? _c("button", {
                  domProps: { innerHTML: _vm._s(_vm.sliderData.button.title) },
                  on: {
                    click: function ($event) {
                      return _vm.changeLocation(_vm.sliderData.button.url)
                    },
                  },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vue-agile",
        {
          staticClass: "slider-container",
          attrs: {
            "nav-buttons": false,
            "autoplay-speed": 3500,
            speed: 300,
            fade: "fade",
            "pause-on-dots-hover": false,
            pauseOnHover: false,
            autoplay: "autoplay",
          },
        },
        _vm._l(_vm.sliderData.slides, function (data, index) {
          return _c("img", {
            key: index,
            staticClass: "slide",
            attrs: {
              src:
                _vm.windowWidth < 768 && data.imgSmall != null
                  ? data.imgSmall
                  : data.img,
            },
            on: {
              click: function ($event) {
                return _vm.changeLocation(data.url)
              },
            },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
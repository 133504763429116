var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", {
      staticClass: "wizard__title",
      domProps: { textContent: _vm._s(_vm.content.step_1.title) },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "wizard__s1-grid" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: _vm.content.step_1.type_1.img_src,
            alt: _vm.content.step_1.title,
          },
          on: {
            click: function ($event) {
              return _vm.setType("classic")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wizard__s1-grid-row" }, [
        _c("h3", {
          staticClass: "wizard__subtitle",
          domProps: { textContent: _vm._s(_vm.content.step_1.type_1.button) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s1-table" }, [
          _c("div", {
            staticClass: "wizard__s1-table-key",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_1.dimensions.length_label
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-value",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_1.dimensions.length_value
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-key",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_1.dimensions.width_label
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-value",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_1.dimensions.width_value
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-key",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_1.dimensions.height_label
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-value",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_1.dimensions.height_value
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c("button", {
          staticClass: "wizard__button",
          domProps: { textContent: _vm._s(_vm.content.step_1.type_1.button) },
          on: {
            click: function ($event) {
              return _vm.setType("classic")
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "wizard__s1-grid" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: _vm.content.step_1.type_2.img_src,
            alt: _vm.content.step_1.title,
          },
          on: {
            click: function ($event) {
              return _vm.setType("cubic")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wizard__s1-grid-row" }, [
        _c("h3", {
          staticClass: "wizard__subtitle",
          domProps: { textContent: _vm._s(_vm.content.step_1.type_2.button) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "wizard__s1-table" }, [
          _c("div", {
            staticClass: "wizard__s1-table-key",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_2.dimensions.length_label
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-value",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_2.dimensions.length_value
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-key",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_2.dimensions.width_label
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-value",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_2.dimensions.width_value
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-key",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_2.dimensions.height_label
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "wizard__s1-table-value",
            domProps: {
              textContent: _vm._s(
                _vm.content.step_1.type_2.dimensions.height_value
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c("button", {
          staticClass: "wizard__button",
          domProps: { textContent: _vm._s(_vm.content.step_1.type_2.button) },
          on: {
            click: function ($event) {
              return _vm.setType("cubic")
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
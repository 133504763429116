require(['jquery'], function ($) {
  $.migrateMute = true;
  $.migrateTrace = false;
});

import Vue from 'vue';
import HomepageSlider from './components/HomepageSlider.vue';
import Wizard from './components/Wizard.vue';
import store from './store';
import HomeMenu from './includes/HomeMenu';
import WooCommerce from './woocommerce';

Vue.config.devtools = false;
Vue.config.debug = false;
Vue.config.silent = true;
Vue.config.productionTip = false;

class Nahka {
  constructor() {
    new HomeMenu();
    new WooCommerce();
    this.homepageSlider = document.getElementById('homepageSlider');
    this.wizard = document.getElementById('ProductWizard');

    if (this.homepageSlider) {
      this.initHomepageSlider(this.homepageSlider);
    }
    if (this.wizard) {
      this.initWizard(this.wizard);
    }
  }

  initHomepageSlider(element) {
    const data = element.getAttribute('data-slider');

    new Vue({
      el: '#homepageSlider',
      render: (h) =>
        h(HomepageSlider, {
          props: {
            data: data,
          },
        }),
    });
  }

  initWizard() {
    new Vue({
      store,
      created() {
        store.dispatch('fetchWizardData');
      },
      el: '#ProductWizard',
      render: (h) => h(Wizard, {}),
    });
  }
}

window.onload = function () {
  new Nahka();
};

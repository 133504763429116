var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.wizardData
    ? _c(
        "div",
        { staticClass: "wizard wizard__container" },
        [
          _c(
            "form-wizard",
            {
              ref: "wizard",
              attrs: {
                title: "",
                subtitle: "",
                shape: "square",
                color: "#EEEEED",
                stepSize: "xs",
              },
            },
            [
              _c(
                "tab-content",
                [_c("step-one", { on: { nextStep: _vm.nextStep } })],
                1
              ),
              _vm._v(" "),
              _c(
                "tab-content",
                [
                  _c("step-two", {
                    on: { nextStep: _vm.nextStep, prevStep: _vm.prevStep },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "tab-content",
                [
                  _c("step-three", {
                    on: { nextStep: _vm.nextStep, prevStep: _vm.prevStep },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("tab-content", [_c("step-four")], 1),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "hidden" }),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _vm.debug ? _c("debug") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }